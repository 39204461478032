import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import Img from "gatsby-image";
import _map from "lodash/map";

import { Typography } from "@material-ui/core";

const Item = styled.div`
  border-radius: 4px;
  > h6 {
    text-align: center;
    padding-top: 10px;
  }
`;

const Poster = styled(Img)`
  border-radius: 4px;
  height: 270px;
  margin: 0 15px;
`;

const OneLineSlider = ({ items }) => {
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",

          marginRight: "50px",
          zIndex: 1,
        }}
        onClick={onClick}
      />
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", marginLeft: "50px", zIndex: 1 }}
        onClick={onClick}
      />
    );
  };

  const SETTINGS = {
    dots: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    focusOnSelect: true,
    accessibility: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...SETTINGS}>
      {_map(items, ({ node }) => {
        const { id, poster, title } = node;
        return (
          <Item key={id}>
            <Poster {...poster} />
            <Typography variant="subtitle1">{title}</Typography>
          </Item>
        );
      })}
    </Slider>
  );
};

export default OneLineSlider;
