import React, { useEffect, useRef } from "react";
import _ from "lodash";
import styled from "styled-components";
import Img from "gatsby-image";
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActionArea,
  CardActions,
  Button,
  IconButton,
  Typography,
} from "@material-ui/core";
import isMediaFileVideo from "../utils/isMediaFileVideo";

import ShareIcon from "@material-ui/icons/Share";

const CardClickableArea = styled(CardActionArea)`
  height: 440px;
`;

const MediaItem = (heroMedia) => {
  const Wrapper = styled.div`
    height: 47%;
    overflow: hidden;
  `;

  return (
    <Wrapper>
      {isMediaFileVideo(heroMedia) ? (
        <CardMedia
          component="video"
          autoPlay
          muted
          loop
          src={_.get(heroMedia, "file.url")}
        />
      ) : (
        <Img {...heroMedia} />
      )}
    </Wrapper>
  );
};

const BlogPostCard = ({
  title,
  slug,
  description,
  heroImage,
  publishDate,
  tags,
}) => {
  const Content = styled.div`
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    p {
      margin-block-start: 0.5em;
      margin-block-end: 0.5em;
    }
  `;

  return (
    <Card>
      <CardClickableArea href={`/blog/${slug}`}>
        {heroImage && <MediaItem {...heroImage} />}
        <CardHeader
          title={
            <Typography variant="h6" color="textPrimary" component="h2">
              {title}
            </Typography>
          }
          subheader={publishDate}
        />
        <CardContent
          style={{ paddingTop: 0, height: "70px", overflow: "hidden" }}
        >
          <Content
            dangerouslySetInnerHTML={{
              __html: description.childMarkdownRemark.html,
            }}
          />
        </CardContent>
      </CardClickableArea>
      <CardActions style={{ justifyContent: "space-between" }}>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <Button variant="text" color="body" href={`/blog/${slug}`}>
          Read more
        </Button>
      </CardActions>
    </Card>
  );
};

export default BlogPostCard;
