import React from "react";
import { SvgIcon } from "@material-ui/core";

const ModellingIcon = () => (
  <SvgIcon viewBox="0 0 480.009 480.009">
    <g>
      <g>
        <path
          d="M351.978,328.009h-64v-44.992c29.662-17.125,47.953-48.757,48-83.008v-40h8v-16h-23.016
			c-11.094-0.014-20.738-7.616-23.344-18.4l-17.936-63.76c-0.968-3.454-4.117-5.841-7.704-5.84c-3.575-0.001-6.716,2.37-7.696,5.808
			l-16.48,57.952c-3.559,14.238-16.348,24.23-31.024,24.24h-80.8v16h8v40c0.047,34.251,18.338,65.883,48,83.008v52.992
			c0,2.122,0.844,4.156,2.344,5.656l40,40c3.124,3.123,8.188,3.123,11.312,0l37.656-37.656h68.688c22.08,0.026,39.974,17.92,40,40
			v80h-304v-8h-16v16c0,4.418,3.582,8,8,8h320c4.418,0,8-3.582,8-8v-88C407.943,353.096,382.891,328.045,351.978,328.009z
			 M271.978,332.697l-32,32l-32-32v-42.288c20.68,7.466,43.32,7.466,64,0V332.697z M239.978,280.009c-44.183,0-80-35.817-80-80v-40
			h56.8c21.946,0.052,41.115-14.83,46.504-36.104l8.68-30.52l10.224,36.328c4.358,17.432,19.828,29.801,37.792,30.216v40.08
			C319.978,244.192,284.161,280.009,239.978,280.009z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M151.978,328.009h-24v16h24c22.091,0,40,17.909,40,40c0,22.091-17.909,40-40,40h-40c-13.255,0-24-10.745-24-24h-16
			c0.026,22.08,17.92,39.974,40,40h40c30.928,0,56-25.072,56-56C207.978,353.081,182.906,328.009,151.978,328.009z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M421.208,294.278c-5.679-5.067-12.331-8.923-19.55-11.332l0-0.032c4.084-5.458,6.3-12.087,6.32-18.904
			c0-17.673-14.327-32-32-32v-92.384c1.094-73.624-55.939-135.073-129.44-139.464C171.514-3.462,107.759,54.42,104.136,129.443
			c-0.106,2.187-0.158,4.377-0.158,6.566v96c-17.673,0-32,14.327-32,32c0.02,6.817,2.236,13.446,6.32,18.904
			c-29.324,9.83-45.127,41.571-35.297,70.895c7.636,22.78,28.952,38.15,52.977,38.201h48v-16h-48c-22.091,0-40-17.909-40-40
			c0-22.091,17.909-40,40-40h48v-16h-40c-8.837,0-16-7.163-16-16c0-8.837,7.163-16,16-16h8c4.418,0,8-3.582,8-8v-104
			c-0.003-66.274,53.721-120.002,119.995-120.005c1.941,0,3.882,0.047,5.821,0.141c64.953,4.012,115.257,58.412,114.184,123.48
			v100.384c0,4.418,3.582,8,8,8h8c8.837,0,16,7.163,16,16c0,8.837-7.163,16-16,16h-40v16h48c22.091,0,40,17.909,39.999,40.001
			c0,9.838-3.626,19.33-10.183,26.663l11.92,10.672C446.304,350.267,444.286,314.867,421.208,294.278z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M199.978,178.009c-7.732,0-14,6.268-14,14s6.268,14,14,14s14-6.268,14-14
			C213.969,184.281,207.706,178.018,199.978,178.009z M197.978,192.009c0-1.105,0.895-2,2-2s2,0.895,2,2
			C201.978,194.209,197.978,194.209,197.978,192.009z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M279.978,178.009c-7.732,0-14,6.268-14,14c0.009,7.728,6.272,13.991,14,14c7.732,0,14-6.268,14-14
			S287.71,178.009,279.978,178.009z M277.978,192.009c0-1.105,0.895-2,2-2c1.105,0,2,0.895,2,2
			C281.978,194.209,277.978,194.209,277.978,192.009z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M257.978,232.009c0,9.941-8.059,18-18,18c-9.941,0-18-8.059-18-18h-12c0,16.569,13.431,30,30,30s30-13.431,30-30H257.978z
			"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </SvgIcon>
);

export default ModellingIcon;
