import React from "react";
import styled from "styled-components";
import map from "lodash/map";
import contentfulServices from "./../../services";
import NavPills from "../../components/NavPills/NavPills.js";

import { Grid, Button } from "@material-ui/core";

import {
  DanceIcon,
  TheatreIcon,
  MusicIcon,
  TaekwondoIcon,
  GymIcon,
  BalletIcon,
  ModellingIcon,
  KindermusicIcon,
} from "../../components/Icons";

const Services = ({ services }) => {
  const getIcon = (iconName) =>
    ({
      DanceIcon,
      TheatreIcon,
      MusicIcon,
      TaekwondoIcon,
      GymIcon,
      BalletIcon,
      ModellingIcon,
      KindermusicIcon,
    }[iconName]);

  const sortedSerives = services.sort(
    (a, b) => a.node.orderNumber - b.node.orderNumber
  );

  const TabContent = ({ slug, label, description }) => {
    const TabContentContainer = styled.div`
      display: flex;
      flex-direction: column;
      align-items: center;
    `;

    const TabContentWrapper = styled.div`
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 40px;
    `;

    const TabContentWrapperItem = styled.div`
      width: 100%;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    `;

    return (
      <TabContentContainer>
        <TabContentWrapper>
          <TabContentWrapperItem>
            {contentfulServices.renderRichText(description.json)}
          </TabContentWrapperItem>
          <TabContentWrapperItem>
            <img src="/banner.jpg" />
          </TabContentWrapperItem>
        </TabContentWrapper>
        {slug && (
          <Button
            variant="outlined"
            color="primary"
            size="large"
            component="a"
            href={`/services/${slug}`}
          >
            {`More about ${label}`}
          </Button>
        )}
      </TabContentContainer>
    );
  };

  const tabs = map(sortedSerives, ({ node }) => {
    return {
      tabColor: node.color,
      tabButton: node.label,
      tabIcon: getIcon(node.iconPicker),
      tabContent: node.description ? <TabContent {...node} /> : <div />,
    };
  });

  return (
    <Grid container>
      <Grid item>
        <NavPills color="primary" tabs={tabs} />
      </Grid>
    </Grid>
  );
};

export default Services;
