import React from "react";
import styled from "styled-components";
import { Box, Button, Container, Typography } from "@material-ui/core";

const ButtonCTA = styled(Button)`
  color: #000;
  border-color: #000;
`;

const BottomButton = ({ label, href, color }) => {
  return (
    <Container maxWidth="lg">
      <Box mt={6} display="flex" justifyContent="center" alignItems="center">
        <ButtonCTA
          size="large"
          color={color}
          component="a"
          href={href}
          variant="outlined"
        >
          {label}
        </ButtonCTA>
      </Box>
    </Container>
  );
};

export default BottomButton;
