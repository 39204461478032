import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "./styles";

const useStyles = makeStyles(styles);

const Parallax = (props) => {
  let windowScrollTop =
    window.innerWidth >= 768 ? window.pageYOffset / 3 : (windowScrollTop = 0);

  const [transform, setTransform] = React.useState(
    "translate3d(0," + windowScrollTop + "px,0)"
  );

  const resetTransform = () => {
    var windowScrollTop = window.pageYOffset / 3;
    setTransform("translate3d(0," + windowScrollTop + "px,0)");
  };

  React.useEffect(() => {
    if (window.innerWidth >= 768) {
      window.addEventListener("scroll", resetTransform);
    }

    const cleanup = () => {
      if (window.innerWidth >= 768) {
        window.removeEventListener("scroll", resetTransform);
      }
    };

    return cleanup;
  });

  const {
    filter,
    className,
    children,
    style,
    image,
    staticComponent,
    small,
  } = props;

  const classes = useStyles();
  const parallaxClasses = classNames({
    [classes.parallax]: true,
    [classes.filter]: filter,
    [classes.small]: small,
    [className]: className !== undefined,
  });
  return (
    <div
      className={parallaxClasses}
      // style={{
      //   ...style,
      //   backgroundImage: "url(" + image + ")",
      //   transform: transform,
      // }}
    >
      <div
        className={classes.staticComponent}
        style={{
          ...style,
          transform: transform,
        }}
      >
        {staticComponent}
      </div>
      {children}
    </div>
  );
};

Parallax.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.string,
  image: PropTypes.string,
  small: PropTypes.bool,
};

export default Parallax;
