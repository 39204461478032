import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { Grid, Paper, CardActionArea } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BottomButton from "../../components/BottomButton";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "15px",
  },
  paper: {
    padding: "15px",
    textAlign: "center",
  },
}));

const Offer = styled.div`
  display: block;
  margin: 0 10px;
  flex: 1;
`;

const Offers = ({ offers }) => {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={3}>
          {offers.map(({ node }) => {
            return (
              <Offer key={node.id}>
                <CardActionArea href={`/offer/${node.slug}`}>
                  <Paper className={classes.paper}>
                    <Img fluid={node.offerImage.fluid} />
                  </Paper>
                </CardActionArea>
              </Offer>
            );
          })}
        </Grid>
      </Grid>
      <BottomButton label={"view offers"} href={"/offers"} color="secondary" />
    </>
  );
};

export default Offers;
