import React from "react";
import { Grid } from "@material-ui/core";
import BlogPostCard from "../../components/BlogPostCard";
import BottomButton from "../../components/BottomButton";

const ArticlesPreview = ({ articles }) => {
  return (
    <>
      <Grid container justify="center" alignItems="flex-start" spacing={3}>
        {articles.map(({ node }) => {
          return (
            <Grid key={node.slug} item xs={12} sm={6} md={4} lg={4} xl={4}>
              <BlogPostCard {...node} />
            </Grid>
          );
        })}
      </Grid>
      <BottomButton label={"more news"} href={"/blog"} color={"secondary"} />
    </>
  );
};

export default ArticlesPreview;
