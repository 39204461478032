import React, { useEffect, useRef, useMemo } from "react";
import _ from "lodash";
import Slider from "react-slick";
import styled from "styled-components";
import Img from "gatsby-image";
import { Typography, Button } from "@material-ui/core";

import isMediaFileVideo from "../../utils/isMediaFileVideo";

const Slide = styled.div`
  z-index: 1;
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.primary.dark};
  position: relative;
`;

const SlideContentWrapper = styled.div`
  z-index: 2;
  background-color: ${({ theme }) => theme.palette.primary.dark};
  height: 100%;
  /* border-bottom-right-radius: 80%; */
`;

const SlideMediaWrapper = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  max-height: 67%;
  overflow: hidden;
  border-bottom-left-radius: 30%;
  padding: 1px;
`;

const SlideVideo = styled.video``;

const SlideImage = styled(Img)`
  height: 100%;
`;

const SlideContent = styled.div`
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  color: #fff;
  left: 10%;
  bottom: 50%;
  transform: translate(0, 50%);
  font-size: 14px;
  max-width: 50%;

  > h2 {
    width: 100%;
    display: inline-block;
    letter-spacing: 0.07rem;
    font-weight: 700;
    line-height: 1.35;
    color: #fff;
    > span {
      width: 100%;
      background-color: ${(props) => (props.color ? props.color : "#000")};
      background-color: #000;
      margin: 0.5em 0;
    }
  }

  > h3 {
    color: #fff;
    max-width: 70%;
    margin-bottom: 25px;
    > span {
      width: 100%;
      background-color: ${(props) => (props.color ? props.color : "#000")};
      background-color: #000;
      margin: 0.5em 0;
    }
  }
`;

const ButtonCTA = styled(Button)`
  color: #fff;
  border-color: #fff;
  z-index: 999;
  &:hover {
    color: #000;
    background-color: #fff;
    border-color: #fff;
  }
`;

const SlideMediaItem = (heroMedia) => {
  const videoEl = useRef(null);
  useEffect(() => {
    if (_.get(videoEl, "current")) {
      videoEl.current.play();
    }
  }, [videoEl]);

  return isMediaFileVideo(heroMedia) ? (
    <SlideVideo autoplay loop muted ref={videoEl} width="100%">
      <source
        src={_.get(heroMedia, "file.url")}
        type={_.get(heroMedia, "file.contentType")}
      />
    </SlideVideo>
  ) : (
    <SlideImage {...heroMedia} />
  );
};

const Carousel = ({ slides }) => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const orderedSlides = useMemo(
    () => _.orderBy(_.map(slides, "node"), ["order"], ["asc"]),
    [slides]
  );
  return (
    <Slider {...settings}>
      {orderedSlides.map((slide) => {
        const {
          id,
          colourPicker,
          title,
          description,
          heroImage,
          serviceLink,
          buttonText,
        } = slide;
        return (
          <Slide key={id}>
            <SlideContentWrapper>
              <SlideContent color={colourPicker}>
                <Typography variant="h2" component="h2">
                  <span>{title}</span>
                </Typography>
                {description && (
                  <Typography variant="h6" component="h3">
                    <span>{description}</span>
                  </Typography>
                )}
                {serviceLink && (
                  <ButtonCTA
                    variant="outlined"
                    color="primary"
                    size="large"
                    component="a"
                    href={`/services/${serviceLink.slug}`}
                  >
                    {buttonText ? buttonText : "Find out more"}
                  </ButtonCTA>
                )}
              </SlideContent>
            </SlideContentWrapper>
            {heroImage && (
              <SlideMediaWrapper>
                <SlideMediaItem {...heroImage} />
              </SlideMediaWrapper>
            )}
          </Slide>
        );
      })}
    </Slider>
  );
};

export default Carousel;
