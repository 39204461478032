import React from "react";
import styled from "styled-components";
import Parallax from "../Parallax";
import Carousel from "../Carousel";

const Hero = ({ slides }) => {
  const HeroWrapper = styled.div`
    margin-top: -100px;
    z-index: 0;
  `;

  const carouselComponent = <Carousel slides={slides} />;
  const bannerComponent = <div />;

  return (
    <HeroWrapper>
      <Parallax
        filter
        staticComponent={slides ? carouselComponent : bannerComponent}
      />
    </HeroWrapper>
  );
};

export default Hero;
