import React from "react";
import styled from "styled-components";
import OneLineSlider from "../../components/OneLineSlider";

const Events = ({ events }) => {
  const Wrapper = styled.div``;

  return (
    <Wrapper>
      <OneLineSlider items={events} />
    </Wrapper>
  );
};

export default Events;
