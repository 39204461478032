import React from "react";
import styled from "styled-components";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { Typography } from "@material-ui/core";

const Container = styled.div`
  text-align: center;
  h2 {
    padding-bottom: 30px;
  }
`;

const TextBlock = () => {
  return (
    <Container>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <Typography variant="subtitle1" color="textPrimary" component="p">
            {`Diamantidou School of Music, Dance & Drama is a modern school
            established in 1995. We offer a wide choice of various directions in
            arts and talents’ development. Our classes range from music, dance
            and theatrical workshops as well as gym, modelling and taekwondo.
            For our younger students we offer afternoon creative classes.`}
          </Typography>
        </GridItem>
      </GridContainer>
    </Container>
  );
};

export default TextBlock;
