import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import get from "lodash/get";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import Hero from "../components/Hero";
import TextBlock from "../components/TextBlock";
import RaisedContent from "../components/RaisedContent";
import Layout from "../components/layout";
import Section from "../sections/Section";
import ArticlePreview from "../sections/ArticlesPreview";
import Services from "../sections/Services";
import Offers from "../sections/Offers";
import Events from "../sections/Events";
import Contact from "../sections/Contact";

import styles from "../assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

const Wrapper = styled.div`
  overflow: hidden; // important
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  ${({ theme }) => `
      position: relative;
      z-index: 3;
      margin: -60px 15px 0px;
      padding: 60px 15px;
      ${theme.breakpoints.up("xs")} {
        margin: -60px 15px 0px;
        padding: 60px 15px;
      }
      ${theme.breakpoints.up("sm")} {
        margin: -60px 15px 0px;
        padding: 60px 15px;
      }
      ${theme.breakpoints.up("md")} {
        margin: -60px 15px 0px;
        padding: 60px 15px;
      }
      ${theme.breakpoints.up("lg")} {
        margin: -60px 30px 0px;
        padding: 60px 30px;
      }
    `}
`;

const RootIndex = (props) => {
  const originLink = get(props, "location.originLink");
  const posts = get(props, "data.allContentfulBlogPost.edges");
  const bannerSlides = get(props, "data.allContentfulBannerSlide.edges");
  const services = get(props, "data.allContentfulServices.edges");
  const offers = get(props, "data.allContentfulOffers.edges");
  const events = get(props, "data.allContentfulEvents.edges");
  return (
    <Layout location={props.location}>
      <div style={{ background: "#fff" }}>
        <Helmet>
          <title>{`Diamantidou School of Music, Dance & Drama`}</title>
          <meta
            name="description"
            content={
              "Diamantidou School of Music, Dance & Drama offers a wide choice of various directions in arts and talent development. Our classes range from music, dance and theatrical workshops as well as gym, modelling and taekwondo. For our younger students we offer afternoon creative classes."
            }
          />
          <meta
            name="keywords"
            content={
              "Musical theatre limassol, limassol, music, dance, theatre, hip hop, ballet, summer school, kindermusic, diamantidou"
            }
          />
          {originLink && <link rel="canonical" href={originLink} />}
          <link
            rel="stylesheet"
            type="text/css"
            charset="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
          {/*  Fonts and icons  */}
          <link
            rel="stylesheet"
            type="text/css"
            href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Roboto+Slab:400,700|Material+Icons"
          />
          <link
            rel="stylesheet"
            href="https://maxcdn.bootstrapcdn.com/font-awesome/latest/css/font-awesome.min.css"
          />
        </Helmet>
        <Hero slides={bannerSlides} />
        <RaisedContent>
          <Wrapper>
            <Section title={`Diamantidou School`}>
              <TextBlock />
            </Section>
            <Section title={`Latest news`}>
              <ArticlePreview articles={posts} />
            </Section>
            <Section title={`Our services`}>
              <Services services={services} />
            </Section>
            <Section title={`Diamantidou School presents`} fullWidth>
              <Events events={events} />
            </Section>
            <Section title={`Our offers`}>
              <Offers offers={offers} />
            </Section>
          </Wrapper>
        </RaisedContent>
      </div>
      <Container>
        <Wrapper>
          <Section title={`Contact us`}>
            <Contact />
          </Section>
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default RootIndex;

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      limit: 6
    ) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            file {
              url
              contentType
            }
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulBannerSlide {
      edges {
        node {
          id
          order
          title
          heroImage {
            file {
              contentType
              url
            }
            fluid(resizingBehavior: PAD, background: "rgb:000000") {
              ...GatsbyContentfulFluid
            }
          }
          description
          serviceLink {
            slug
          }
          buttonText
        }
      }
    }
    allContentfulServices {
      edges {
        node {
          id
          slug
          label
          iconPicker
          color
          orderNumber
          description {
            json
          }
          heroImage {
            file {
              url
              contentType
            }
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
    allContentfulEvents {
      edges {
        node {
          id
          poster: poster {
            fluid(resizingBehavior: PAD, background: "rgb:000000") {
              ...GatsbyContentfulFluid
            }
          }
          title
          eventDate
        }
      }
    }
    allContentfulOffers(filter: { showOnHomePage: { eq: true } }) {
      edges {
        node {
          id
          showOnHomePage: title
          offerImage: offerImage {
            fluid(resizingBehavior: PAD, background: "rgb:000000") {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`;
